

let field = document.querySelector('[name=your-subject]');

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
let contactName = params.cn; // "some_value"

if (field && contactName) {
  field.value = 'To the attention of ' + contactName;
}