window.addEventListener("DOMContentLoaded", function () {
  setHhCtaHeights();
});

window.addEventListener(
  "resize",
  function (event) {
    setHhCtaHeights();
  },
  true
);

function setHhCtaHeights() {
  var hhctas = document.querySelectorAll(
    '[is="flynt-layout-home-header"] .ctas details summary'
  );
  var ctasBiggest = 0;
  if (hhctas) {
    hhctas.forEach((el) => {
      el.style.height = "auto";
      if (
        el.offsetHeight > ctasBiggest &&
        window.matchMedia("(min-width:768px)").matches
      ) {
        ctasBiggest = el.offsetHeight;
      }
    });
    if (window.matchMedia("(min-width:768px)").matches) {
      hhctas.forEach((el) => {
        el.style.height = ctasBiggest + "px";
      });
    }
  }
}
